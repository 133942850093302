<template>
  <div id="singleModule" style="background: #F5F9FA;height:520px;overflow-y:hidden;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="contentBox">
        <div id="container"></div>
        <div style="margin-left:34px">
          <div class="item">
            <i class="iconfont wemeet-dianhua"></i>
            <span class="label">电话</span>
            <span>+86 13764584032</span>
          </div>
          <div class="item">
            <i class="iconfont wemeet-youxiang"></i>
            <span class="label">邮箱</span>
            <span>954314001@qq.com</span>
          </div>
          <div class="item">
            <i class="iconfont wemeet-dizhi"></i>
            <span class="label">地址</span>
            <span class="content">上海普陀区 绿州中环中心 915 弄 18楼1801 </span>
          </div>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'companyDesc',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '联系我们',
    }
  },

  mounted() {
    var center = new TMap.LatLng(31.234246,121.380835)
    var map = new TMap.Map(document.getElementById('container'), {
      center: center, //设置地图中心点坐标
      zoom: 17.2, //设置地图缩放级别
    })
    var markerLayer = new TMap.MultiMarker({
      map: map, //指定地图容器
      //样式定义
      styles: {
        //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
        myStyle: new TMap.MarkerStyle({
          width: 35, // 点标记样式宽度（像素）
          height: 35, // 点标记样式高度（像素）
          src: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/aboutUs/address.png', //图片路径
          //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
          anchor: { x: 16, y: 32 },
        }),
      },
      //点标记数据数组
      geometries: [
        {
          id: '1', //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          styleId: 'myStyle', //指定样式id
          position: new TMap.LatLng(31.234246,121.380835), //点标记坐标位置
          properties: {
            //自定义属性
            title: 'marker1',
          },
        },
      ],
    })
  },

  methods: {},
}
</script>

<style lang="less" scoped>
#container {
  width: 806px;
  height: 250px;
}
.contentBox {
  display: flex;
  justify-content: space-between;
  .item {
    margin-bottom: 32px;
    display: flex;
    i {
      font-size: 26px;
      color: #5abec1;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .label {
      margin-left: 4px;
      margin-right: 22px;
      width: 50px;
    }
    .content {
      width: 300px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      overflow: hidden;
    }
  }
}
</style>