<template>
  <div>
    <el-carousel height="1080px" :pause-on-hover='pauseonHover'>
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <img :src="item.url" alt="轮播图" style="width:100%;height:100%;object-fit:cover;">
        <div class="wordBox">
          <h2 class="bigTitle">开好每个会，服务全社会</h2>
          <h2 class="smallTitle">跨越时空 相见相联</h2>
          <div class="playButton" v-if="item.isVideo" @click="playVideo(item)">
            <i class="wemeet-bofanganniu iconfont"></i>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <video :src="videoItem.videoUrl" style="width:100%;height:540px;" controls :poster='videoItem.url'></video>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'banner',

  data() {
    return {
      carouselList: [
        {
          url: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/banner-1.png',
          name: '',
          isVideo: false,
        },
        {
          url: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/videoPoster1.png',
          name: '',
          isVideo: true,
          videoUrl: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/video1.mp4',
        },
        {
          url: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/videoPoster2.png',
          name: '',
          isVideo: true,
          videoUrl: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/video2.mp4',
        },
      ],
      videoItem: '',
      dialogVisible: false,
      pauseonHover: false,
    }
  },

  mounted() {},

  methods: {
    playVideo(videoItem) {
      this.videoItem = videoItem
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false 
    },
  },
}
</script>

<style lang="less" scoped>
.el-carousel__item {
  width: 100vw;
  background: #d8d8d8;
}
.wordBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .playButton {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #5abec1;
    text-align: center;
    line-height: 80px;
    margin: 35px auto 0 auto;
    cursor: pointer;
    .wemeet-bofanganniu {
      font-size: 30px;
      color: #fff;
    }
  }

  .bigTitle {
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .smallTitle {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>

<style>
.el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  background-color: #ccf2f8 !important;
  display: block;
  border-radius: 50% !important;
  opacity: 1 !important;
}
.el-carousel__indicator.is-active button {
  width: 30px !important;
  height: 10px !important;
  background: #5abec1 !important;
  border-radius: 6px !important;
}
</style>