<template>
  <div id="singleModule" style="background: #fff;height:auto;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="partners">
        <img :src="`https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/partner/partner${index + 1}.png`"  class="partnersImg" alt="合作伙伴" v-for="(item, index) in 12" :key="index">
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'partner',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '合作伙伴',
      autoPlay: false,
    }
  },

  mounted() {},

  methods: {},
}
</script>

<style lang="less" scoped>
.partners {
  padding-bottom: 178px;
  display: grid;
  /* grid-gap: 30px 50px */
  grid-column-gap: 25px;
  grid-row-gap: 27px;
  /* 一行放两列、两个auto */
  grid-template-columns: auto auto auto auto;
  .partnersImg{
    width: 287px;
    height: 98px;
  }
}
</style>