<template>
  <div id="singleModule" style="background: rgba(90, 190, 193, 0.1);">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="aboutBox">
        <img class="icon" src="@/assets/home/aboutUsIcon.png">
        <h3 class="title">关于中栾空间设计</h3>
        <div class="introduce">
          <p>中栾空间设计有限公司成立于2021年，注册资金100万元人民币 ，是一家专业从事工业与民用建筑装饰施工、设计的团队。</p>

          <p> 公司经营范围：各种涂料施工、居家、别墅、工业厂房、二次装修及整改、防水工程、水电空调设备安装工程、及各种设计等。多年来，业绩非凡。所承接并完成的各类大、中、小型工程均受到了业主的一致好评</p>

          <p> “信誉第一、质量第一、服务第一”是我们永远遵循的宗旨。尊重传统、推崇时尚、锐意创新、志在超前是我们经久不变的理念。</p>
        </div>
        <!-- <div class="seemore" @click="goAboutUs">
          查看更多
          <i class="iconfont wemeet-chakangengduo"></i>
        </div> -->
        <img class="addressImg" src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/zxgs_yunsheji/gongyi/gongsi5.jpg" alt="公司" />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
export default {
  name: 'aboutUs',
  components: {
    ModuleTitle,
  },
  data() {
    return {
      title: '关于我们',
      autoPlay: false,
    }
  },

  mounted() {},

  methods: {
    goAboutUs() {
      this.$router.push({ path: '/aboutUs'})
    },
  },
}
</script>

<style lang="less" scoped>
.aboutBox {
  width: 820px;
  height: 540px;
  background: #ffffff;
  border-radius: 8px;
  padding: 35px 197px 0 67px;
  box-sizing: border-box;
  position: relative;
  .addressImg {
    width: 492px;
    height: 430px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(77%, -50%);
  }
  .icon {
    width: 31px;
    height: 31px;
  }
  .title {
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    margin-top: 17px;
    margin-bottom: 23px;
  }
  .introduce {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    p {
      margin-bottom: 30px;
    }
  }
  .seemore {
    width: 168px;
    height: 54px;
    border-radius: 27px;
    border: 1px solid #5abec1;
    margin: 0 auto;
    text-align: center;
    line-height: 45px;
    font-size: 22px;
    font-weight: 400;
    color: #5abec1;
    cursor: pointer;
    // box-shadow: 0px 0px 0px 0px rgba(102, 194, 197, 0.53);
    i {
      font-size: 32px;
      color: #66c2c5;
      position: relative;
      top: 5px;
    }
  }
}
</style>