<template>
  <div class="header" :class="[ isFixed ? 'fixed animate__animated animate__slideInDown' : '']">
    <div class="banxin headerInner">
      <div>
        <span class="wemeet-WeMeet-logo1">中栾空间设计</span>
      </div>
      <ul class="menu">
        <li @click="goMenu(item)" class="menuItem" :class="[activeKey == item.activeKey ? 'active': '']" v-for="(item, index) in menuList" :key="index">{{ item. name}}</li>

        <!-- <template v-if="userName == null ">
          <li class="register" @click="goRegsiter()">注册</li>
          <li class="login" @click="goLogin()">登录</li>
        </template>
        <li class="userName" v-if="userName != null ">{{userName}}</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WmtHeader',
  props: {
    activeKey: {
      type: String,
      default: 'home',
    },
  },
  data() {
    return {
      isFixed: false,
      userName: null,
      menuList: [
        {
          activeKey: 'home',
          name: '首页',
          path: '/',
        },
        {
          activeKey: 'live',
          name: '样板案例',
          path: '/liveServer',
        },
        {
          activeKey: 'news',
          name: '工艺一览',
          path: '/newsCenter',
        },
        // {
        //   activeKey: 'aboutUs',
        //   name: '关于我们',
        //   path: '/aboutUs',
        // },
        // {
        //   activeKey: 'joinUs',
        //   name: '加入我们',
        //   path: '/joinUs',
        // },
      ],
    }
  },
  methods: {
    goMenu(e) {
      this.$router.push({ path: e.path })
    },
    goLogin() {
      this.$router.push({ path: '/login' })
    },
    goRegsiter() {
      this.$router.push({ path: '/register' })
    },
  },
  mounted() {
    this.$bus.$on('statusChange', () => {
      console.log('mounted触发', localStorage.getItem('userName'))
      this.userName = localStorage.getItem('userName')
    })

    this.userName = localStorage.getItem('userName')
    console.log(this.userName)

    document.onscroll = (e) => {
      if (window.scrollY > 100) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }
    }
  },
}
</script>

<style lang='less'>
.header {
  width: 100vw;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 99;
  padding-top: 10px;
  box-sizing: border-box;
  overflow: auto;
  // background: red;
  &.fixed {
    background: #fff;
    .wemeet-WeMeet-logo1 {
      color: #5abec1;
    }
    .menu {
      .register {
        width: 60px;
        height: 32px;
        border-radius: 16px;
        border: 1px solid #5abec1;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5abec1;
      }
      .userName {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
      }
    }
    .menuItem {
      color: #333;
      font-size: 16px;
      margin: 0 47px;
      cursor: pointer;
      &.active {
        position: relative;
        &::after {
          content: '';
          background-color: #5abec1;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 4px;
          border-radius: 20px;
        }
      }
    }
  }
}
.banxin {
  width: 1200px;
  overflow: auto;
  margin: 0 auto;
}
.wemeet-WeMeet-logo1 {
  font-size: 30px;
  color: #fff;
}
.headerInner {
  display: flex;
  justify-content: space-between;
  // background: grey;
}
.menu {
  display: flex;
  height: 40px;
  line-height: 40px;
  .register {
    width: 60px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid #ffffff;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 5px;
    cursor: pointer;
  }
  .userName {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .login {
    width: 60px;
    height: 32px;
    background: #5abec1;
    border-radius: 16px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
  }
  .menuItem {
    color: #fff;
    font-size: 16px;
    margin: 0 47px;
    cursor: pointer;
    &.active {
      position: relative;
      &::after {
        content: '';
        background-color: #fff;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 4px;
        border-radius: 20px;
        transition: all 0.3s;
      }
    }
  }
}
</style>