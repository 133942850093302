<template>
  <div id="singleModule" style="background: #fff;height:auto;padding-bottom:122px;">
    <div class="moduleContainer">
      <ModuleTitle :title='title'></ModuleTitle>

      <div class="news">
        <NewsItem v-for="(item, index) in newsList" :key="index" :item='item'></NewsItem>
      </div>

      <div class="seemore" @click="goDetail()">
        查看更多
        <i class="iconfont wemeet-chakangengduo"></i>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ModuleTitle from './moduleTitle.vue'
import NewsItem from '@/components/newsItem/newsItem.vue'
export default {
  name: 'newsCenter',
  components: {
    ModuleTitle,
    NewsItem,
  },
  data() {
    return {
      title: '比赛展览',
      newsList: [
        {
          id: 1,
          name: '2022ADM十周年 | 所有的道别里，我最喜欢“明年见',
          date: '2022-11-07 21:53',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover1.png',
          desc: `尾声潮落，ADM的2022年秋日限定就要结束了。至此，一年一度的不定义之约划上了十全十美的句号。
十年很长，十年很短，无数个我汇聚成我们，无数创不可思议变成创新常态，历史就是这样，一步步走到我们的跟前。遽然回望，轻舟已过万重山。第一个十年，ADM的关键词是「兴XING」，「兴」可以是新的起点，也可以是万物生长，都描述了时间的变化。投射到ADM身上，是品牌吸收和理解城市的文化和历史，对它开出更个性化、可复制化和可持续化的良药。第一个十年，ADM的关键词是「兴XING」，「兴」可以是新的起点，也可以是万物生长，都描述了时间的变化。投射到ADM身上，是品牌吸收和理解城市的文化和历史，对它开出更个性化、可复制化和可持续化的良药。`,
        },
        {
          id: 2,
          name: 'ADM十周年大展如约而至！剧透第一弹来袭！',
          date: ' 2022-10-10 19:51',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover2.png',
          desc: `在《五十公里桃花坞》里李雪琴曾说“我对浪漫的定义是：兴之所起，乘兴而至，乘兴而归。”而今天，即将迎来十周岁生日的ADM想对大家说“11月3日-11月7日，武林之星博览中心ADM MORE欢迎各位乘兴而来，满载而归！”`,
        },
        {
          id: 3,
          name: '正式官宣 | 2022ADM十周年定档！诚邀你在数字时代下共赴“兴”纪元',
          date: '2022-08-02 20:10',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover3.png',
          desc: `九年来，ADM始终坚持自己的责任与使命，为大众呈现当下设计趋势的审美标准与高度，迭代发展新现场和新场景，此外这中间走过的每一步，都留下了每一个曾经的你的烙印。如今ADM迎来了第一个十周岁生日，我们在此邀请你，一起来为ADM庆生！在此之前先来回顾看看2021ADM大展盛况吧！
2021年，我们提出了“设计即使命Design As Mission”的概念，向大众传达出一种标准，这标准的背后是我们价值的体现。今年，疫情对于世界的继续破坏仍将产生不可逆的影响，世界也将重新编码，制定新的规则。作为走过了九年的ADM，在十周年的节点上，我们将以全新的身份再出发，同时向你讲述全新的故事。`,
        },
        {
          id: 4,
          name: 'ADM十周年×狮虎桥路|一起到闹中取静的后花园里放肆去嗨，ADM送你「高兴一夏夏」',
          date: '2022-08-04 23:19',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover4.png',
          desc: `苍天为穹，大街为厅，今天晚上“武林衣秀” 风云再起，2022杭州仲夏夜——拱墅区“武林衣秀·宋韵杭州”暨武林路女人街开街仪式在武林广场顺利举行。这场活动由拱墅区人民政府、杭州市商务局主办，杭州会展集团·西博文化运营执行。今年是“武林衣秀”二十周年，本次的武林衣秀除了T台走秀以外，还融入了宋韵元素。宋画活化演艺环节，根据南宋画院画家手笔，结合舞蹈和走秀表演，立体呈现宋代场景及仕女风采。华裳秀典，七夕锦绣。恰逢七夕，“武林衣秀”在这个环节展现我国古时情侣、婚嫁服饰。武林侠女秀上，黄蓉、阿朱……这些金庸武侠小说中的侠女，从经典著作中走出，一展武侠宋韵。穿越千年宋“潮”，今日弄潮的“杭派女装”品牌一一登场：COCOON、蓝色倾情、洛可可、秋水伊人、JASONWOOD、INXX、J11、SENNOS&ZHOU、mija礼服等品牌共飨时尚盛宴，七夕特别篇礼服大秀甜蜜收尾。`,
        },
        {
          id: 5,
          name: '助力2021世界人工智能大会，WeMeet Cube 智能会议舱亮相世博展览馆',
          date: '2021-07-20 19:41',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover5.png',
          desc: `助力2021世界人工智能大会的召开，东浩兰生和 WeMeet 紧密合作，在世博展览馆共同推出 WeMeet Cube 智能会议舱服务，为大会参会者提供全新的体验。WeMeet Cube 是一个移动的会议空间，它小巧灵活，占地空间仅为2.2mLx2.4mDx2.3mH，配备了万向脚轮和固定支撑脚，移动固定随心切换，适应不同场地需求。它还有着55英寸Maxhub专业会议平板设备、支持WeMeet微信小程序扫码预约使用的智能门锁，内置照明系统、电源、网络接口、排风扇，还配备了防火地毯、会议桌椅、环保吸音棉、隔音板、聚酯纤维阻燃吸音板和钢化玻璃。此次大会中，世博展览馆内的3个 WeMeet Cube 得到了充分的利用。诸多参展商、买家以及媒体记者等嘉宾免费扫码体验了WeMeet Cube，实现了大会现场的无缝洽谈、随地工作，促进了大会的圆满召开。WeMeet 愿与海内外朋友携手努力，共创智能时代的美好未来。`,
        },
        {
          id: 6,
          name: 'ADM×差评 | 弥合数字鸿沟，别让“边缘人群”被数字时代的“扫一扫”所抛弃',
          date: '2021-07-09 19:41',
          cover: 'https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/website/news/newsCover6.png',
          desc: `回忆一下，从何时开始，我们的身边布满了诸如“扫码付款”、“扫码注册”、“在线查看”之类的字眼？
随着数字技术的快速发展，打车、转账、网购、游戏…… 智能手机改变了我们的生活方式，为我们提供了无限的便捷与欢乐。但也正是从那时开始，我们的身边频频出现“用现金买东西被拒”、“买不到火车票痛哭流涕”、“没有健康码无法乘坐公共交通”等现象。这些在常人眼里，只需“扫一扫”就能轻松完成的动作，正是老年人、儿童、残障人士等“边缘人群”难以克服的障碍。日趋复杂的智能手机和五花八门的APP，早已成为了他们融入数字生活的“绊脚石”，就像一道难以逾越的“数字鸿沟”。`,
        },
      ],
    }
  },

  mounted() {},

  methods: {
    goDetail() {
      this.$router.push({ path: '/newsCenter' })
    },
  },
}
</script>

<style lang="less" scoped>
.news {
  display: grid;
  /* grid-gap: 30px 50px */
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  /* 一行放两列、两个auto */
  grid-template-columns: auto auto auto;
}
.seemore {
  width: 168px;
  height: 54px;
  border-radius: 27px;
  border: 1px solid #5abec1;
  margin: 0 auto;
  text-align: center;
  line-height: 45px;
  font-size: 22px;
  font-weight: 400;
  color: #5abec1;
  margin-top: 43px;
  // box-shadow: 0px 2px 6px 0px rgba(102, 194, 197, 0.53);
  cursor: pointer;
  i {
    font-size: 32px;
    color: #66c2c5;
    position: relative;
    top: 5px;
    // box-shadow: 0px 2px 6px 0px rgba(102, 194, 197, 0.53);
  }
}
</style>